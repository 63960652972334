import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useParams, useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Card, CardContent, CardHeader, Chip, Divider, Stack, Typography } from '@mui/material';
import { useStyles } from '../../styles/styles';
import PlaceIcon from '@mui/icons-material/Place';
import WorkIcon from '@mui/icons-material/Work';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ExpertiseIcon from '../../assets/images/cards/icon_expertise.svg';
import WorkArrangementIcon from '../../assets/images/cards/icon_work.svg';
import LocationIcon from '../../assets/images/cards/icon_location.svg';
import SalaryIcon from '../../assets/images/cards/icon_salary.svg';
import { format } from '../../utils/DateUtil';
import { ITool } from '../../interfaces/ITool';
import { ISkill } from '../../interfaces/ISkill';
import { capitalName, formateBudgetRange } from '../../helpers/Helpers';
import { PromoCard } from '../common/PromoCard';
import { ISection } from '../../interfaces/ISection';
import { Constants } from '../../utils/Constants';
import { ApplyForm } from './ApplyForm';
import { IPublicPage } from '../../interfaces/IPublicPage';
import { getDataById } from '../../services/PublicPagesApi';
import Navbar from '../common/Navbar';
import SEO from '../common/SEO';
import { setIndividualUser } from '../../actions/UsersActions';
import { getTalentCloudOwner } from '../../services/UserApi';
import { IUser } from '../../interfaces/IUser';
import PlaceholderImage from '../../assets/images/placeholder-project.svg';
import PlaceholderLogo from '../../assets/images/placeholder_logo.svg';
import CircleIcon from '@mui/icons-material/Circle';
import { Statuses } from '../../constants/ApplicationConstants';
import { findStageByStageId } from '../../utils/workflows';
import { getWorkflowById } from '../../services/WorkflowApi';

export const Overview = () => {
  const { id, identifier } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data: IPublicPage = useSelector((state: RootState) => state.publicPages.byIdData);
  const section: ISection = useSelector((state: RootState) => state.sections.sectionData);
  const currentUser = useSelector((state: RootState) => state.users.currentUser);
  const workflow = useSelector((state: RootState) => state.workflows.workflow);
  const classes = useStyles();
  const [openApplyForm, setOpenApplyForm] = useState(false);
  const owner = useSelector((state: RootState) => state.users.user);
  const [displayName, setDisplayName] = useState<string | undefined>('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    const companyDisplayName = data.company_display_name;
    const hiringManagerDisplayName = data.hiring_manager_display_name;

    if (companyDisplayName && hiringManagerDisplayName) {
      setDisplayName(`${companyDisplayName}, ${hiringManagerDisplayName}`);
    } else if (companyDisplayName) {
      setDisplayName(companyDisplayName);
    } else if (hiringManagerDisplayName) {
      setDisplayName(hiringManagerDisplayName);
    } else {
      setDisplayName(data.hiring_manager);
    }
  }, [data.company_display_name, data.hiring_manager_display_name, data.hiring_manager]);

  useEffect(() => {
    if (data?.tenant_id) {
      if (data.status === Statuses.IN_APPROVAL_WORKFLOW && data.workflow_approval_id) {
        dispatch(getWorkflowById(data.tenant_id, data.workflow_approval_id));
      } else if (data.status === Statuses.IN_HIRING_WORKFLOW && data.workflow_id) {
        dispatch(getWorkflowById(data.tenant_id, data.workflow_id));
      }
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (workflow && data.status) {
      if (data.status === 'draft') {
        setStatus(capitalName(data.status));
      } else {
        if (data.status === Statuses.IN_APPROVAL_WORKFLOW) {
          const stageId = data.workflow_approval_stage_id;
          const stage = findStageByStageId(workflow, stageId);
          if (stage) {
            setStatus(stage.name);
          }
        } else {
          const stageId = data.workflow_stage_id;
          const stage = findStageByStageId(workflow, stageId);
          const completedStages = ['onboarding', 'offer_accepted', 'hired', 'job_complete'];
          if (stage) {
            if (completedStages.includes(stage.stage_type)) {
              setStatus(capitalName('job_completed'));
            } else {
              setStatus(capitalName('open'));
            }
          }
        }
      }
    }
  }, [data.status, data.workflow_approval_stage_id, data.workflow_stage_id, workflow]);

  useEffect(() => {
    if (identifier === 'talent_clouds' && data.owner_id) dispatch(getTalentCloudOwner(data.owner_id));
  }, [dispatch, data, identifier]);

  const handleClickOpen = () => {
    setOpenApplyForm(true);
  };

  const handleExpertClick = () => {
    if (identifier === 'opportunities') {
      window.location.href = `${process.env.REACT_APP_FRONTEND_CORE_SERVICE}/expert/opportunities/${id}/details?expertApplies=true`;
    } else {
      window.location.href = `${process.env.REACT_APP_FRONTEND_CORE_SERVICE}/expert/datas/${id}/details?expertApplies=true`;
    }
  };

  useEffect(() => {
    dispatch(getDataById(id, identifier));
  }, [identifier, id, dispatch]);

  useEffect(() => {
    dispatch(setIndividualUser({} as IUser));
    window.scrollTo(0, 0);
  }, [dispatch]);

  const handleBacklink = () => {
    const tab = data?.is_project ? 1 : identifier === 'opportunities' ? 0 : 2;
    switch (tab) {
      case 0:
        return navigate('/careers/experfy/jobs');
      case 1:
        return navigate('/careers/experfy/projects');
      case 2:
        return navigate('/careers/experfy/talentclouds');
      default:
        return;
    }
  };

  return (
    <>
      <SEO title='Job Opportunity | Project | Overview' />
      <Navbar />
      {data && (
        <Box sx={{ marginTop: '3.5rem' }}>
          <Box className={classes.mainHeader}>
            <Box className={classes.contentContainer}>
              <Box className={classes.mainHeaderInner}>
                <Box className={classes.mainHeaderContent}>
                  <Box className={classes.mainHeaderReturn} onClick={handleBacklink}>
                    <KeyboardArrowLeftIcon />
                    Back to Browse Jobs Opportunities and Projects
                  </Box>
                  <Typography variant='h3'>{data.job_title}</Typography>
                  <Stack className={classes.mainHeaderOrigin} direction='row'>
                    {identifier === 'talent_clouds' ? (
                      <>{owner?.first_name && <Typography className={`${classes.jobCardOrigin}`}>{`${owner.first_name} ${owner.last_name}`}</Typography>}</>
                    ) : (
                      <>{displayName?.trim() !== '' && <Typography className={`${classes.jobCardOrigin}`}>{displayName}</Typography>}</>
                    )}
                    <Typography>posted {format(data.created_at, 'MMM DD, YYYY')}</Typography>
                  </Stack>
                  <Stack className={classes.mainHeaderFeatures} direction='row'>
                    {data.ideal_candidate_location && (
                      <Typography>
                        <PlaceIcon />
                        {data.ideal_candidate_location}
                      </Typography>
                    )}
                    {data.job_type && (
                      <Typography>
                        <WorkIcon />
                        {data.job_type}
                      </Typography>
                    )}
                    {(data.remunerations || data.min_rate || data.max_rate) && (
                      <Typography>
                        <MonetizationOnIcon />
                        {data.remunerations} {(data.min_rate || data.max_rate) && formateBudgetRange(data.remunerations, data.min_rate, data.max_rate)}
                      </Typography>
                    )}
                    {data?.number_of_resources && data?.number_of_resources !== 0 && (
                      <Typography>
                        <PersonIcon />
                        {data.number_of_resources} {data.number_of_resources > 1 ? 'Positions' : 'Position'}
                      </Typography>
                    )}
                  </Stack>
                </Box>
                <Divider orientation='vertical' flexItem />

                <Box className={classes.mainHeaderActions}>
                  {currentUser.id ? (
                    <>
                      {currentUser.user_type === 'expert' ? (
                        <>
                          <Button size='large' variant='contained' color='success' sx={{ width: '100%' }} onClick={handleExpertClick}>
                            Apply
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button size='large' variant='contained' color='success' sx={{ width: '100%' }} disabled={true}>
                            Apply
                          </Button>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {status === 'Open' ? (
                        <>
                          <Button size='large' variant='contained' color='success' sx={{ width: '100%' }} onClick={handleClickOpen}>
                            Apply
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button size='large' variant='contained' color='success' sx={{ width: '100%' }} disabled={true}>
                            Apply
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.mainNav}>
            <Box className={classes.contentContainer}>
              <Box className={classes.mainNavInner}>
                <Typography className={classes.mainNavItem}>Overview</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.mainContent}>
            <Box className={classes.contentContainer}>
              <Box className={classes.jobDetails}>
                <Box className={classes.jobDetailsContent}>
                  <Card>
                    <CardContent>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.description ?? '',
                        }}
                      ></div>
                    </CardContent>
                  </Card>
                </Box>
                <Box className={classes.jobDetailsFeatures}>
                  {data?.is_project && (
                    <Card>
                      <CardContent>
                        <Box className={classes.jobDetailsCardThumb}>
                          <Box className={classes.jobDetailsCardThumbInner}>
                            {data.project_thumbnail ? (
                              <>
                                <Box className={classes.jobDetailsCardThumbImage} sx={{ backgroundImage: `url(${data.project_thumbnail})` }}></Box>
                              </>
                            ) : (
                              <>
                                <Box className={classes.jobDetailsCardThumbImage} sx={{ backgroundImage: `url(${PlaceholderImage})`, backgroundSize: 'contain' }}></Box>
                              </>
                            )}
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                  <Card className='no-header'>
                    <CardContent>
                      <Stack className={classes.dataGroupInline} direction='row'>
                        <Typography>Status:</Typography>
                        <Typography className={`${classes.jobDetailsStatus} is-${status.toLowerCase().replace(/\s/g, '-')}`}>
                          <CircleIcon />
                          <span className='status-text'>{`${status === 'Closed' ? 'Hiring Closed' : status}`}</span>
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                  {section.expertise && (
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ backgroundColor: '#4ba4da' }} aria-label='Expertise'>
                            <img src={ExpertiseIcon} alt='Expertise' />
                          </Avatar>
                        }
                        title='Expertise'
                      />
                      <CardContent>
                        {data.skill_levels && data.skill_levels.length > 0 && (
                          <Box className={classes.tagsGroup}>
                            <Typography variant='h5'>Experience Level</Typography>
                            <Box>
                              {data.skill_levels.map((exp: string, index: number) => (
                                <Chip label={exp} key={index} />
                              ))}
                            </Box>
                          </Box>
                        )}
                        {data.educations?.length > 0 && (
                          <Box className={classes.tagsGroup}>
                            <Typography variant='h5'>Preferred Education</Typography>
                            <Box>
                              {data.educations.map((edu: string, index: number) => (
                                <Chip label={edu} key={index} />
                              ))}
                            </Box>
                          </Box>
                        )}
                        {data.certifications?.length > 0 && (
                          <Box className={classes.tagsGroup}>
                            <Typography variant='h5'>Preferred Certifications</Typography>
                            <Box>
                              {data.certifications.map((cert: string, index: number) => (
                                <Chip label={cert} key={index} />
                              ))}
                            </Box>
                          </Box>
                        )}

                        {data.skills && data.skills.length > 0 && (
                          <Box className={classes.tagsGroup}>
                            <Typography variant='h5'>Functional Compentencies</Typography>
                            <Box>
                              {data.skills.map((skill: ISkill, index: number) => (
                                <Chip label={skill.name} key={index} />
                              ))}
                            </Box>
                          </Box>
                        )}
                        {data.tools && data.tools.length > 0 && (
                          <Box className={classes.tagsGroup}>
                            <Typography variant='h5'>Technology & Tools</Typography>
                            <Box>
                              {data.tools?.map((tool: ITool, index: number) => (
                                <Chip label={tool.name} key={index} />
                              ))}
                            </Box>
                          </Box>
                        )}
                        {data.industry && data.industry.length > 0 && (
                          <Box className={classes.tagsGroup}>
                            <Typography variant='h5'>Industry</Typography>
                            <Box>
                              {data.industry.map((industry: string, index: number) => (
                                <Chip label={industry} key={index} />
                              ))}
                            </Box>
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  )}

                  {section.work_arrangement && (
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ backgroundColor: '#4ba4da' }} aria-label='Work Arrangement'>
                            <img src={WorkArrangementIcon} alt='Work Arrangement' />
                          </Avatar>
                        }
                        title='Work Arrangement'
                      />
                      <CardContent>
                        {data.job_type && (
                          <Stack className={classes.dataGroup}>
                            <Typography variant='h6'>Job Type</Typography>
                            <Typography>{data.job_type}</Typography>
                          </Stack>
                        )}
                        {data.time_commitment && (
                          <Stack className={classes.dataGroup}>
                            <Typography variant='h6'>Minimum Time Commitment</Typography>
                            <Typography>{data.time_commitment} Hours/Week</Typography>
                          </Stack>
                        )}
                        {data.preferred_working_hours && (
                          <Stack className={classes.dataGroup}>
                            <Typography variant='h6'>Preferred Working Hours</Typography>
                            <Typography>{data.preferred_working_hours}</Typography>
                          </Stack>
                        )}
                      </CardContent>
                    </Card>
                  )}
                  {section.location && (
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ backgroundColor: '#fff' }} aria-label='Location'>
                            <img src={LocationIcon} alt='Location' />
                          </Avatar>
                        }
                        title='Location'
                      />
                      <CardContent>
                        {data.ideal_candidate_location && (
                          <Stack className={classes.dataGroup}>
                            <Typography variant='h6'>Ideal Candidate Location</Typography>
                            <Typography>{data.ideal_candidate_location}</Typography>
                          </Stack>
                        )}
                        {data.open_to_remote && (
                          <Stack className={classes.dataGroup}>
                            <Typography variant='h6'>Open to Remote</Typography>
                            <Typography>{data.open_to_remote === true ? 'Yes' : 'No'}</Typography>
                          </Stack>
                        )}
                        {data.travel_requirement && (
                          <Stack className={classes.dataGroup}>
                            <Typography variant='h6'>Percentage of Travel Required</Typography>
                            <Typography>{data.travel_requirement}</Typography>
                          </Stack>
                        )}
                      </CardContent>
                    </Card>
                  )}
                  {section.compensation && (
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ backgroundColor: '#fff' }} aria-label='Compensation'>
                            <img src={SalaryIcon} alt='Compensation' />
                          </Avatar>
                        }
                        title='Compensation'
                      />
                      <CardContent>
                        {data.remunerations && (
                          <Stack className={classes.dataGroup}>
                            <Typography>{data.remunerations}</Typography>
                          </Stack>
                        )}
                        {(data.min_rate || data.max_rate) && (
                          <Stack className={classes.dataGroup}>
                            {Object.entries(Constants.emunerations.range_titles).map(([k, v], index: number) => {
                              return (
                                <Typography variant='h6' key={index}>
                                  {k === data.remunerations && v}
                                </Typography>
                              );
                            })}
                            <Typography>{formateBudgetRange(data.remunerations, data.min_rate, data.max_rate)}</Typography>
                          </Stack>
                        )}
                      </CardContent>
                    </Card>
                  )}
                  <Card>
                    <CardContent>
                      <Box className={classes.jobDetailsCardOwner}>
                        <Stack
                          direction='row'
                          spacing={2}
                          sx={{
                            alignItems: 'center',
                          }}
                        >
                          <Avatar alt='Remy Sharp' src={PlaceholderLogo} />
                          <Box>
                            <Typography variant='body1' my={0}>
                              Posted by
                            </Typography>
                            {identifier === 'talent_clouds' ? (
                              <>{owner?.first_name && <Typography variant='h6' my={0}>{`${owner.first_name} ${owner.last_name}`}</Typography>}</>
                            ) : (
                              <>
                                {displayName?.trim() !== '' && (
                                  <Typography variant='h6' my={0}>
                                    {displayName}
                                  </Typography>
                                )}
                              </>
                            )}
                            <Typography variant='h6' my={0}>
                              {format(data.created_at, 'MMM DD, YYYY')}
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
              <PromoCard />
            </Box>
          </Box>
          <ApplyForm identifier={identifier} openApplyForm={openApplyForm} setOpenApplyForm={setOpenApplyForm}></ApplyForm>
        </Box>
      )}
    </>
  );
};
