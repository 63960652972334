import React, { useEffect, useState } from 'react';
import { IPublicPage } from '../../interfaces/IPublicPage';
import { IFilter } from '../../interfaces/IFIlter';
import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import LinkTag from '@mui/material/Link';
import { format } from '../../utils/DateUtil';
import PlaceIcon from '@mui/icons-material/Place';
import CheckIcon from '@mui/icons-material/Check';
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useStyles } from '../../styles/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { IUser } from '../../interfaces/IUser';

interface IRecordProps {
  record: IPublicPage;
  identifier: string;
  reloadParams: IFilter;
}

function Record(props: IRecordProps) {
  const { record, identifier, reloadParams } = props;
  const classes = useStyles();
  const users = useSelector((state: RootState) => state.users.usersData);
  const [owner, setOwner] = useState<IUser>({} as IUser);
  const [displayName, setDisplayName] = useState<string | undefined>('');

  useEffect(() => {
    const companyDisplayName = record.company_display_name;
    const hiringManagerDisplayName = record.hiring_manager_display_name;

    if (companyDisplayName && hiringManagerDisplayName) {
      setDisplayName(`${companyDisplayName}, ${hiringManagerDisplayName}`);
    } else if (companyDisplayName) {
      setDisplayName(companyDisplayName);
    } else if (hiringManagerDisplayName) {
      setDisplayName(hiringManagerDisplayName);
    } else {
      setDisplayName(record.hiring_manager);
    }
  }, [record.company_display_name, record.hiring_manager_display_name, record.hiring_manager]);

  useEffect(() => {
    if (record.owner_id && users.length > 0) {
      const user = users.find((user: IUser) => user.id === record.owner_id);
      if (user) setOwner(user);
    }
  }, [record, users]);

  const onJobClick = () => {
    const serializedParams = JSON.stringify(reloadParams);
    window.localStorage.setItem('params', serializedParams);
    window.location.href = `/careers/experfy/${identifier}/${record._id}/overview`;
  };

  return (
    <Card className={classes.jobCard} key={record._id}>
      <CardContent>
        <Box className={classes.jobCardInfo}>
          <Typography className={classes.jobCardTitle} variant='h3'>
            <LinkTag onClick={onJobClick}>{record.job_title}</LinkTag>
          </Typography>
          <Stack className={classes.jobCardOrigin} direction='row'>
            {identifier === 'talent_clouds' ? (
              <>{owner?.first_name && <Typography className={`${classes.jobCardOrigin} hiringManager`}>{`${owner.first_name} ${owner.last_name}`}</Typography>}</>
            ) : (
              <>{displayName?.trim() !== '' && <Typography className={`${classes.jobCardOrigin} hiringManager`}>{displayName}</Typography>}</>
            )}
            <Typography>posted {format(record.created_at, 'MMM DD, YYYY')}</Typography>
          </Stack>
          <Box className={classes.jobCardSumary}>
            <div dangerouslySetInnerHTML={{ __html: record.description ?? '' }}></div>
          </Box>
        </Box>
        <Divider orientation='vertical' flexItem />
        <Box className={classes.jobCardFeatures}>
          {record.ideal_candidate_location && (
            <Typography>
              <PlaceIcon />
              {record.ideal_candidate_location}
            </Typography>
          )}
          {record.open_to_remote === true && (
            <Typography>
              <CheckIcon />
              Open to Remote
            </Typography>
          )}
          {record.job_type && (
            <Typography>
              <WorkIcon />
              {record.job_type}
            </Typography>
          )}
          <Typography>
            <MonetizationOnIcon />
            {record.remunerations}
          </Typography>
          {/* {(record.min_rate || record.max_rate) && (
            <Typography>
              <MonetizationOnIcon />
              {formateBudgetRange(record.remunerations, record.min_rate, record.max_rate)}
            </Typography>
          )} */}
          {record.number_of_resources && record.number_of_resources !== 0 && (
            <Typography>
              <PersonIcon />
              {record.number_of_resources} {record.number_of_resources > 1 ? 'Positions' : 'Position'}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default Record;
