import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { UsePagination } from '../common/Pagination';
import Record from './Record';
import { IPublicPage } from '../../interfaces/IPublicPage';
import { getOpportunities } from '../../services/OpportunitiesApi';

export const Opportunities = ({ ...props }) => {
  const { reloadParams, handlePageChange, currentPage, loading } = props;
  const dispatch = useDispatch();
  const opportunitiesMetaData = useSelector((state: RootState) => state.opportunities.opportunitiesMetaData);

  useEffect(() => {
    if (reloadParams.filters !== undefined) dispatch(getOpportunities(reloadParams, false));
  }, [dispatch, reloadParams]);

  return (
    <>
      {(reloadParams.filters !== '' || reloadParams.keywords?.length > 0) && !loading && (
        <Typography variant='h4'>{`${opportunitiesMetaData?.meta?.page?.total} ${opportunitiesMetaData?.meta?.page?.total > 1 ? 'records' : 'record'}  matching your criteria`}</Typography>
      )}

      {opportunitiesMetaData?.records &&
        opportunitiesMetaData?.records.map((row: IPublicPage, index: number) => (
          <div key={index}>
            <Record record={row} identifier='opportunities' reloadParams={reloadParams} />
          </div>
        ))}
      <UsePagination
        count={opportunitiesMetaData?.meta?.page?.total ? Math.ceil(opportunitiesMetaData?.meta?.page?.total / opportunitiesMetaData?.meta?.page?.per_page) : 0}
        page={currentPage}
        handlePageChange={handlePageChange}
      ></UsePagination>
    </>
  );
};
