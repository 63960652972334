import { Box, Grid, SelectChangeEvent } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import { useStyles } from '../../../styles/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { dateRange } from '../../../utils/DateUtil';
import { jobTypesOptions, postedDateOptions, remunerationsOptions } from '../../../constants/FiltersConstants';
import { getPracticeAreas } from '../../../services/PracticeAreaApi';
import dayjs from 'dayjs';
import FormSwitch from '../FormSwitch';


export const AdvanceFilters = ({ ...props }) => {
  const {
    searchFilters,
    setSearchFilters,
    selectedPracticeArea,
    setSelectedPracticeArea,
    selectedWorkType,
    setSelectedWorkType,
    selectedPostDate,
    setSelectedPostDate,
    selectedRemuneration,
    setSelectedRemuneration,
    addFilterTags,
    removeFilterTags,
    brandSwitch,
    setBrandSwitch,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const practiceAreas = useSelector((state: RootState) => state.practiceAreas.practiceAreasData);

  useEffect(() => {
    dispatch(getPracticeAreas());
  }, [dispatch]);

  useEffect(() => {
    const practicArea = practiceAreas.find((p) => p._id === selectedPracticeArea);
    if (practicArea) {
      addFilterTags('practice_areas', practicArea.title);
    } else {
      removeFilterTags('practice_areas');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPracticeArea, practiceAreas]);

  useEffect(() => {
    if (selectedWorkType !== '') {
      addFilterTags('work_type', selectedWorkType);
    } else {
      removeFilterTags('work_type');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkType]);

  useEffect(() => {
    if (selectedPostDate !== 'any-date') {
      const date = postedDateOptions.find((d) => d.id === selectedPostDate);
      addFilterTags('created_at', date ? date.name : selectedPostDate);
    } else {
      removeFilterTags('created_at');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPostDate]);

  useEffect(() => {
    if (selectedRemuneration) {
      addFilterTags('compensation_type', selectedRemuneration);
    } else {
      removeFilterTags('compensation_type');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRemuneration]);

  const handlePracticeAreaChange = (e: SelectChangeEvent) => {
    setSelectedPracticeArea(e.target.value);
    let newSearchFilters = searchFilters;
    const practicArea = practiceAreas.find((p) => p._id === e.target.value);
    if (practicArea) {
      newSearchFilters = { ...newSearchFilters, practice_areas: e.target.value };
    } else {
      delete newSearchFilters['practice_areas'];
      newSearchFilters = { ...newSearchFilters };
    }

    setSearchFilters(newSearchFilters);
  };

  const handleWorkTypeChange = (e: SelectChangeEvent) => {
    setSelectedWorkType(e.target.value);
    let newSearchFilters = searchFilters;
    if (e.target.value) {
      newSearchFilters = { ...newSearchFilters, work_type: e.target.value };
    } else {
      delete newSearchFilters['work_type'];
      newSearchFilters = { ...newSearchFilters };
    }

    setSearchFilters(newSearchFilters);
  };

  /**
   * If input === any_date, we remove created_date filter to fetch opportunities/ TC with any date
   */
  const handlePostDateChange = (e: SelectChangeEvent) => {
    const input = e.target.value;
    setSelectedPostDate(input);
    let newSearchFilters = searchFilters;
    if (input !== 'any-date') {
      let createdDateRange = null;
      if (input === 'today') {
        createdDateRange = dayjs().format('YYYY-MM-DD');
      } else {
        createdDateRange = dateRange(parseInt(input));
      }
      newSearchFilters = { ...newSearchFilters, created_at: createdDateRange };
    } else {
      delete newSearchFilters['created_at'];
      newSearchFilters = { ...newSearchFilters };
    }

    setSearchFilters(newSearchFilters);
  };

  const handleRemunerationChange = (e: SelectChangeEvent) => {
    const remuneration = e.target.value;
    setSelectedRemuneration(remuneration);

    let newSearchFilters = searchFilters;
    if (remuneration) {
      newSearchFilters = {
        ...newSearchFilters,
        compensation_type: remuneration,
      };

      newSearchFilters = { ...newSearchFilters };
    } else {
      delete newSearchFilters['compensation_type'];
      newSearchFilters = { ...newSearchFilters };
    }

    setSearchFilters(newSearchFilters);
  };

  const handleSwitchChange = (e: SelectChangeEvent) => {
    setBrandSwitch((prev: boolean) => !prev);
    let newSearchFilters = searchFilters;
    if (!brandSwitch) {
      newSearchFilters = { ...newSearchFilters, open_to_remote: !brandSwitch };
      addFilterTags('open_to_remote', 'Remote');
    } else {
      delete newSearchFilters['open_to_remote'];
      newSearchFilters = { ...newSearchFilters };
      removeFilterTags('open_to_remote');
    }
    setSearchFilters(newSearchFilters);
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }} mt={2}>
        <Grid item sm={2} xs={12}>
          <Select fullWidth value={selectedPracticeArea} onChange={handlePracticeAreaChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }} IconComponent={KeyboardArrowDownIcon}>
            <MenuItem value=''>{'Select Practice Area'}</MenuItem>
            {practiceAreas.length > 0 &&
              practiceAreas.map((option: any, index: number) => (
                <MenuItem className={classes.selected} id={option._id} value={option._id} key={index}>
                  {option.title}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Select fullWidth value={selectedWorkType} onChange={handleWorkTypeChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }} IconComponent={KeyboardArrowDownIcon}>
            <MenuItem value='' key={'all-0'}>
              {'All Job Types'}
            </MenuItem>
            {jobTypesOptions.map((option: any, index: number) => (
              <MenuItem className={classes.selected} id={option.id} value={option.id} key={index}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Select fullWidth value={selectedPostDate} onChange={handlePostDateChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }} IconComponent={KeyboardArrowDownIcon}>
            {postedDateOptions.map((option: any, index: number) => (
              <MenuItem className={classes.selected} id={option.id} value={option.id} key={index}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Select fullWidth value={selectedRemuneration} onChange={handleRemunerationChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }} IconComponent={KeyboardArrowDownIcon}>
            <MenuItem value='' key={'c-0'}>
              {'Compensation'}
            </MenuItem>
            {remunerationsOptions.map((option: any, index: number) => (
              <MenuItem className={classes.selected} id={option.id} value={option.id} key={index}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Box className={classes.mainFiltersSwitch}>
            <FormSwitch label='Search only open to remote' brandSwitch={brandSwitch} handleSwitchChange={handleSwitchChange} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
