import React from 'react';
import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import './index.css';
import { Box, CssBaseline } from '@mui/material';
import { ManagePublicPages } from './components/publicPages/ManagePublicPages';
import { Overview } from './components/publicPages/Overview';
import { useDispatch } from 'react-redux';
import { setUserAction } from './actions/UsersActions';

function App() {
  const dispatch = useDispatch();
  const helmetContext = {};

  const getAuthTokenCookie = () => {
    const cookies = document.cookie;
    const cookieArray = cookies.split('; ');

    for (let i = 0; i < cookieArray.length; i++) {
      const cookie = cookieArray[i];
      const [name, value] = cookie.split('=');
      if (name === `${process.env.REACT_APP_COOKIE_NAME}`) {
        return decodeURIComponent(value);
      }
    }

    return null;
  };

  const token = getAuthTokenCookie();

  useEffect(() => {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      const { sub, extension_tenant_id, extension_company_id, extension_user_type } = JSON.parse(jsonPayload);
      dispatch(setUserAction({ id: sub, user_type: extension_user_type, token: token, tenant_id: extension_tenant_id, company_id: extension_company_id }));
    }
  }, [dispatch, token]);

  return (
    <HelmetProvider context={helmetContext}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            height: '100%',
          }}
        >
          <Routes>
            <Route path={'/careers'} element={<Navigate to={'/careers/experfy/jobs'} />} />
            <Route path={'/careers/experfy/jobs'} element={<ManagePublicPages propsTab={0} />} />
            <Route path={'/careers/experfy/projects'} element={<ManagePublicPages propsTab={1} />} />
            <Route path={'/careers/experfy/talentclouds'} element={<ManagePublicPages propsTab={2} />} />
            <Route path={'/careers/experfy/:identifier/:id/overview'} element={<Overview />} />
          </Routes>
        </Box>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
