import { Box, Grid, SelectChangeEvent } from '@mui/material';
import { TextField, Select, MenuItem } from '@mui/material';
import { useStyles } from '../../../styles/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect } from 'react';
import FormLocation from '../FormLocation';
import { radiusOptions } from '../../../constants/FiltersConstants';

export const BasicFilters = ({ ...props }) => {
  const { searchFilters, setSearchFilters, keywords, setKeywords, country, setCountry, location, setLocation, selectedRadius, setSelectedRadius, addFilterTags, removeFilterTags, points, setPoints } =
    props;
  const classes = useStyles();

  useEffect(() => {
    if (keywords.length === 0) {
      removeFilterTags('keywords');
    } else {
      addFilterTags('keywords', keywords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords]);

  useEffect(() => {
    if (selectedRadius !== '' || location !== '') {
      if (selectedRadius !== '') {
        const radiusObj = radiusOptions.find((r) => r.id === selectedRadius);
        if (radiusObj) addFilterTags('location_coordinates', `${location} within ${radiusObj.name}`);
      } else {
        addFilterTags('location_coordinates', location);
        let newSearchFilters = searchFilters;
        newSearchFilters = {
          ...newSearchFilters,
          location_coordinates: {
            distance: '30mi',
            points: { lat: points.lat, long: points.long },
            location: location,
          },
        };

        setSearchFilters(newSearchFilters);
      }
    } else {
      removeFilterTags('location_coordinates');
      let newSearchFilters = searchFilters;
      delete newSearchFilters['location_coordinates'];
      newSearchFilters = { ...newSearchFilters };
      setSearchFilters(newSearchFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRadius, location, points]);

  const onHandleKeywordsChange = (e: any) => {
    const keyword = e.target.value;
    if (e.code === 'Enter' && keyword !== '') {
      let updatedKeywords = [...keywords];
      updatedKeywords.push(keyword);
      setKeywords(updatedKeywords);
      e.target.value = '';
    }
  };

  useEffect(() => {
    setLocation('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const onCountryChange = (code: string, country: any) => {
    setCountry(code);
  };

  const onCityChange = (place: any) => {
    setLocation(place.formatted_address);
    if (place.geometry && place.geometry.location) {
      const loc = place.geometry.location;
      setPoints({ lat: loc.lat(), long: loc.lng() });
    }
  };

  const handleRadiusChange = (e: SelectChangeEvent) => {
    const radius = e.target.value;
    let newSearchFilters = searchFilters;
    if (radius && points.lat && points.long) {
      setSelectedRadius(radius);
      newSearchFilters = {
        ...newSearchFilters,
        location_coordinates: {
          distance: radius,
          points: points,
          location: location,
        },
      };
    }
    setSearchFilters(newSearchFilters);
  };

  return (
    <>
      <Grid item xs={12}>
        <Box className={classes.mainFiltersInline}>
          <TextField fullWidth variant='outlined' placeholder='Add job titles, Keyword' onKeyDown={(e) => onHandleKeywordsChange(e)} />
          <FormLocation onSelectCountry={onCountryChange} country={country} onChangeLocation={onCityChange} location={location} inForm={true} />

          <Select
            fullWidth
            value={selectedRadius}
            onChange={handleRadiusChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            IconComponent={KeyboardArrowDownIcon}
            className='for-range'
          >
            <MenuItem value=''>{'Radius'}</MenuItem>
            {radiusOptions.map((option: any, index: number) => (
              <MenuItem className={classes.selected} id={option.id} value={option.id} key={index}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Grid>
    </>
  );
};
