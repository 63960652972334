import { Box, Typography, Tab, Tabs } from '@mui/material';
import { useStyles } from '../../styles/styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { Filters } from '../common/Filters/Filters';
import Navbar from '../common/Navbar';
import { IFilter } from '../../interfaces/IFIlter';
import Loader from '../common/Loader';
import SEO from '../common/SEO';
import { Opportunities } from './Opportunities';
import { PromoCard } from '../common/PromoCard';
import { Projects } from './Projects';
import { useLocation, useNavigate } from 'react-router-dom';

import { getOpportunitiesAction } from '../../actions/OpportunitiesActions';
import { IPublicPageMeta } from '../../interfaces/IPublicPageMeta';
import { TalentClouds } from './TalentClouds';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TabState {
  tab: number;
}

function PostingTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ManagePublicPages = ({ ...props }) => {
  const { propsTab } = props;
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.loader.loading);
  const storedParams = window.localStorage.getItem('params') ? JSON.parse(window.localStorage.getItem('params') || '') : ({} as IFilter);
  const [currentPage, setCurrentPage] = useState(storedParams.page || 1);
  const [reloadParams, setReloadParams] = useState<IFilter>({ filters: storedParams.filters, keywords: storedParams.keywords, page: currentPage });
  const { tab } = location.state ? (location.state as TabState) : { tab: propsTab };
  const [value, setValue] = useState(tab || 0);
  const [resetFilters, setResetFilters] = useState(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentPage(1);
    dispatch(getOpportunitiesAction({} as IPublicPageMeta));
    setReloadParams({ filters: '', keywords: [], page: 1 });
    setResetFilters(!resetFilters);
    switch (newValue) {
      case 0:
        return navigate('/careers/experfy/jobs');
      case 1:
        return navigate('/careers/experfy/projects');
      case 2:
        return navigate('/careers/experfy/talentclouds');
      default:
        return;
    }
  };

  const handlePageChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: number) => {
    window.scrollTo(0, 450);
    setCurrentPage(value);
    setReloadParams({ ...reloadParams, page: value });
  };

  return (
    <>
      <SEO title='Browse Job Opportunities | Projects' />
      <Navbar />
      {loading && <Loader />}
      <Box className={classes.jobsBanner}>
        <Box className={classes.jobsBannerInner}>
          <Typography className={classes.jobsBannerTitle} variant='h1'>
            Exclusive jobs, contracts and projects rightly matched and delivered to you.
          </Typography>
          <Typography className={classes.jobsBannerText}>
            Experfy is building an innovative Future of work model that will bring exclusive jobs, contracts and projects to you in a hassle free way. Once you become our Talent Community member,
            instead of you applying to jobs, hiring managers seek you out, giving you the attention you deserve. Create a profile today!
          </Typography>
        </Box>
      </Box>
      <Box className={classes.mainHeader}>
        <Box className={classes.contentContainer}>
          <Box className={classes.mainHeaderInner}>
            <Typography variant='h3'>Browse Job Opportunities and Projects</Typography>
          </Box>
        </Box>
      </Box>
      <Filters reloadParams={reloadParams} setReloadParams={setReloadParams} resetFilters={resetFilters} setCurrentPage={setCurrentPage} />
      <Box className={classes.mainTabs}>
        <Box className={classes.contentContainer}>
          <Box className={classes.mainTabsInner}>
            <Tabs value={value} onChange={handleChange} aria-label='posting tabs'>
              <Tab label='Jobs' {...a11yProps(0)} />
              <Tab label='Projects' {...a11yProps(1)} />
              {/* <Tab label='TalentClouds' {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
        </Box>
      </Box>
      <Box className={classes.mainContent}>
        <Box className={classes.contentContainer}>
          <PostingTabPanel value={value} index={0}>
            <Opportunities reloadParams={reloadParams} handlePageChange={handlePageChange} currentPage={currentPage} loading={loading} />
          </PostingTabPanel>
          <PostingTabPanel value={value} index={1}>
            <Projects reloadParams={reloadParams} handlePageChange={handlePageChange} currentPage={currentPage} loading={loading} />
          </PostingTabPanel>
          <PostingTabPanel value={value} index={2}>
            <TalentClouds reloadParams={reloadParams} handlePageChange={handlePageChange} currentPage={currentPage} loading={loading} />
          </PostingTabPanel>
          <PromoCard />
        </Box>
      </Box>
    </>
  );
};
