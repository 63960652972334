import dayjs, { OpUnitType, QUnitType } from 'dayjs';

export const diff = (date1: string | undefined, date2: string | undefined, unit: QUnitType | OpUnitType) => {
  if (!date1 || !date2) {
    return dayjs(new Date()).format('MMM, DD YYYY');
  }
  const difference = dayjs(date2).diff(date1, unit);
  return difference;
};

export const format = (date1: string | undefined, formatStr?: string) => {
  if (date1) {
    formatStr = formatStr ?? 'MMM DD, YYYY';
    return dayjs(date1).format(formatStr);
  }
};

/**
 * The `dateRange` function returns an object with `$gte` and `$lte` properties representing a date
 * range based on the given number of days.
 * @param {number} rangeInDays - The `rangeInDays` parameter is the number of days you want to include
 * in the date range. It determines the start and end dates of the range.
 * @returns an object with two properties: "$gte" and "$lte". The values of these properties are
 * strings representing dates.
 */
export const dateRange = (rangeInDays: number): { $gte: string; $lte: string } | undefined => {
  const currentDate = dayjs();
  return {
    $gte: currentDate.add(-rangeInDays, 'day').format('YYYY-MM-DD'),
    $lte: currentDate.format('YYYY-MM-DD'),
  };
};
