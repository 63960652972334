import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { UsePagination } from '../common/Pagination';
import Record from './Record';
import { IPublicPage } from '../../interfaces/IPublicPage';
import { getTalentClouds } from '../../services/TalentCloudsApi';
import { getUsers } from '../../services/UserApi';

export const TalentClouds = ({ ...props }) => {
  const { reloadParams, handlePageChange, currentPage, loading } = props;
  const dispatch = useDispatch();
  const talentCloudsMetaData = useSelector((state: RootState) => state.talentClouds.talentCloudsMetaData);

  useEffect(() => {
    if (reloadParams.filters !== undefined) dispatch(getTalentClouds(reloadParams));
  }, [dispatch, reloadParams]);

  useEffect(() => {
    if (talentCloudsMetaData.records?.length > 0) {
      const ownerIds = talentCloudsMetaData.records.filter((record: IPublicPage) => record.owner_id).map((record: IPublicPage) => record.owner_id);
      if (ownerIds && ownerIds.length > 0) {
        const uniqOwnerIds = ownerIds.filter((item, index) => ownerIds.indexOf(item) === index);
        dispatch(getUsers(uniqOwnerIds));
      }
    }
  }, [talentCloudsMetaData, dispatch]);

  return (
    <>
      {(reloadParams.filters !== '' || reloadParams.keywords?.length > 0) && !loading && (
        <Typography variant='h4'>{`${talentCloudsMetaData?.meta?.page?.total} ${talentCloudsMetaData?.meta?.page?.total > 1 ? 'records' : 'record'}  matching your criteria`}</Typography>
      )}

      {talentCloudsMetaData?.records &&
        talentCloudsMetaData?.records.map((row: IPublicPage, index: number) => (
          <div key={index}>
            <Record record={row} identifier='talent_clouds' reloadParams={reloadParams} />
          </div>
        ))}
      <UsePagination
        count={talentCloudsMetaData?.meta?.page?.total ? Math.ceil(talentCloudsMetaData?.meta?.page?.total / talentCloudsMetaData?.meta?.page?.per_page) : 0}
        page={currentPage}
        handlePageChange={handlePageChange}
      ></UsePagination>
    </>
  );
};
