import { IPublicPageMeta } from '../interfaces/IPublicPageMeta';
import { Dispatch } from 'redux';
import qs from 'qs';
import { oppApi } from './BaseApi';
import { setLoadingAction } from '../actions/LoaderActions';
import { IFilter } from '../interfaces/IFIlter';
import { getOpportunitiesAction, opportunityErrorAction } from '../actions/OpportunitiesActions';

export const getOpportunities = (searchFilters: IFilter, isProject: boolean) => async (dispatch: Dispatch) => {
  dispatch(setLoadingAction(true));
  let { filters, keywords, page } = searchFilters;
  if (isProject) {
    filters = filters ? `${filters}&filter[is_project]=true` : `filter[is_project]=true`;
  }
  const url = filters ? `/opportunities/public_opportunities?${filters}&page=${page}` : `/opportunities/public_opportunities?page=${page}`;
  try {
    let response: IPublicPageMeta;
    if (keywords?.length > 0) {
      response = await oppApi.get(url, {
        params: {
          keywords: keywords,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      });
    } else {
      response = await oppApi.get(url);
    }

    dispatch(getOpportunitiesAction(response.data));
  } catch (error: any) {
    dispatch(opportunityErrorAction(error));
  }
  dispatch(setLoadingAction(false));
};
