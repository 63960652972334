import { Box, Chip, Grid, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from '../../../styles/styles';
import { useEffect, useState } from 'react';
import { BasicFilters } from './BasicFilters';
import { AdvanceFilters } from './AdvanceFilters';
import { IFilter } from '../../../interfaces/IFIlter';

export const Filters = ({ ...props }) => {
  const { reloadParams, setReloadParams, resetFilters, setCurrentPage } = props;
  const classes = useStyles();
  const storedParams = window.localStorage.getItem('params') ? JSON.parse(window.localStorage.getItem('params') || '') : ({} as IFilter);
  const [searchFilters, setSearchFilters] = useState<{ [key: string]: any }>({});
  const [filterTags, setFilterTags] = useState<{ key: string; value: string }[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [country, setCountry] = useState<string>('US');
  const [location, setLocation] = useState<string>('');
  const [selectedRadius, setSelectedRadius] = useState('');
  const [points, setPoints] = useState<{ lat: string; long: string }>({ lat: '', long: '' });
  const [brandSwitch, setBrandSwitch] = useState(false);
  const [selectedPracticeArea, setSelectedPracticeArea] = useState('');
  const [selectedWorkType, setSelectedWorkType] = useState('');
  const [selectedPostDate, setSelectedPostDate] = useState('any-date');
  const [selectedRemuneration, setSelectedRemuneration] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    setKeywords([]);
    setCountry('US');
    setLocation('');
    setSelectedRadius('');
    setBrandSwitch(false);
    setSelectedPracticeArea('');
    setSelectedWorkType('');
    setSelectedPostDate('any-date');
    setSelectedRemuneration('');
    setFilterTags([]);
    setSearchFilters({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFilters]);

  // Code to restrict
  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filteres from store
  useEffect(() => {
    if (storedParams?.keywords?.length > 0) {
      setKeywords(storedParams.keywords);
    }

    if (storedParams?.filters && storedParams.filters !== '') {
      let newSearchFilters = searchFilters;
      const filtersArray = storedParams.filters.split('&');

      filtersArray.map((filter: string) => {
        const filterKey = filter.split('=')[0];
        const filterValue = filter.split('=')[1];

        if (filterKey.includes('location_coordinates')) {
          const locationCoordinatesObj = JSON.parse(filterValue);
          setSelectedRadius(locationCoordinatesObj.distance);
          setLocation(locationCoordinatesObj.location);
          setPoints(locationCoordinatesObj.points);
          newSearchFilters = {
            ...newSearchFilters,
            location_coordinates: filterValue,
          };
        }

        if (filterKey.includes('open_to_remote')) {
          setBrandSwitch(true);
          newSearchFilters = { ...newSearchFilters, open_to_remote: true };
          addFilterTags('open_to_remote', 'Remote');
        }

        if (filterKey.includes('practice_areas')) {
          setSelectedPracticeArea(filterValue);
          newSearchFilters = {
            ...newSearchFilters,
            practice_areas: filterValue,
          };
        }

        if (filterKey.includes('work_type')) {
          setSelectedWorkType(filterValue);
          newSearchFilters = {
            ...newSearchFilters,
            work_type: filterValue,
          };
        }

        if (filterKey.includes('created_at')) {
          setSelectedPostDate(filterValue);
          newSearchFilters = {
            ...newSearchFilters,
            created_at: filterValue,
          };
        }

        if (filterKey.includes('compensation_type')) {
          setSelectedRemuneration(filterValue);
          newSearchFilters = {
            ...newSearchFilters,
            compensation_type: filterValue,
          };
        }

        return true;
      });

      setSearchFilters(newSearchFilters);
    }

    if (filterTags.length > 0) {
      window.localStorage.removeItem('params');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedParams]);

  //  Other filters
  const addFilterTags = (tagKey: string, tagValue: string) => {
    let updatedTags = [...filterTags];

    if (updatedTags.find((tag) => tag.key === tagKey)) {
      updatedTags = updatedTags.map((tag) => {
        return tag.key === tagKey ? { ...tag, value: tagValue } : tag;
      });
    } else {
      updatedTags = [...updatedTags, { key: tagKey, value: tagValue }];
    }

    setFilterTags(updatedTags);
  };

  const removeFilterTags = (tagKey: string) => {
    let updatedTags = [...filterTags];
    const tag = updatedTags.find((tag) => tag.key === tagKey);

    if (tag) {
      const index = updatedTags.indexOf(tag);
      if (index !== -1) updatedTags.splice(index, 1);
    }

    setFilterTags(updatedTags);
  };

  const handleDeleteKeyword = (keyword: string) => {
    let updatedKeywords = [...keywords];
    const index = updatedKeywords.indexOf(keyword);
    if (index !== -1) updatedKeywords.splice(index, 1);
    setKeywords(updatedKeywords);
  };

  const handleDeleteFilterTag = (tagKey: string) => {
    removeFilterTags(tagKey);
    let newSearchFilters = searchFilters;
    delete newSearchFilters[`${tagKey}`];
    newSearchFilters = { ...newSearchFilters };
    setSearchFilters(newSearchFilters);

    switch (tagKey) {
      case 'location_coordinates':
        setLocation('');
        setSelectedRadius('');
        return;
      case 'open_to_remote':
        setBrandSwitch((prev) => !prev);
        return;
      case 'practice_areas':
        setSelectedPracticeArea('');
        return;
      case 'work_type':
        setSelectedWorkType('');
        return;
      case 'created_at':
        setSelectedPostDate('any-date');
        return;
      case 'compensation_type':
        setSelectedRemuneration('');
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    let newFilters = '';

    Object.keys(searchFilters).map((key: string) => {
      if (searchFilters[key]) {
        const otherFilter = typeof searchFilters[key] === 'object' ? JSON.stringify(searchFilters[key]) : searchFilters[key];
        newFilters = newFilters.length ? `${newFilters}&filter[${key}]=${otherFilter}` : `filter[${key}]=${otherFilter}`;
      }
      return newFilters;
    });

    if (count > 0 && !storedParams.filters) {
      setCurrentPage(1);
      setReloadParams({ ...reloadParams, filters: newFilters, keywords: keywords, page: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords, searchFilters, setReloadParams, count]);

  return (
    <>
      <Box className={classes.mainFilters}>
        <Box className={classes.contentContainer}>
          <Box className={classes.mainFiltersInner}>
            <>
              <Grid container spacing={2}>
                <BasicFilters
                  searchFilters={searchFilters}
                  setSearchFilters={setSearchFilters}
                  country={country}
                  setCountry={setCountry}
                  location={location}
                  setLocation={setLocation}
                  keywords={keywords}
                  setKeywords={setKeywords}
                  selectedRadius={selectedRadius}
                  setSelectedRadius={setSelectedRadius}
                  addFilterTags={addFilterTags}
                  removeFilterTags={removeFilterTags}
                  points={points}
                  setPoints={setPoints}
                />
              </Grid>
              <AdvanceFilters
                searchFilters={searchFilters}
                setSearchFilters={setSearchFilters}
                selectedPracticeArea={selectedPracticeArea}
                setSelectedPracticeArea={setSelectedPracticeArea}
                selectedWorkType={selectedWorkType}
                setSelectedWorkType={setSelectedWorkType}
                selectedPostDate={selectedPostDate}
                setSelectedPostDate={setSelectedPostDate}
                selectedRemuneration={selectedRemuneration}
                setSelectedRemuneration={setSelectedRemuneration}
                addFilterTags={addFilterTags}
                removeFilterTags={removeFilterTags}
                brandSwitch={brandSwitch}
                setBrandSwitch={setBrandSwitch}
              />
              {filterTags.length > 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} mt={1}>
                    <Stack className={classes.tagsGroup} direction='row' spacing={1}>
                      {filterTags.map((tag: any, index: number) => (
                        <>
                          {tag.key === 'keywords' ? (
                            <>
                              {tag.value.map((keyword: string, index: number) => (
                                <Chip key={index} label={keyword} deleteIcon={<CloseIcon />} onDelete={() => handleDeleteKeyword(keyword)} />
                              ))}
                            </>
                          ) : (
                            <>
                              <Chip key={index} label={tag.value} deleteIcon={<CloseIcon />} onDelete={() => handleDeleteFilterTag(tag.key)} />
                            </>
                          )}
                        </>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </>
          </Box>
        </Box>
      </Box>
    </>
  );
};
